/deep/ .el-rate .el-rate__icon{
  margin-right: 0px;
  /*color: red!important;*/
}
/deep/.el-radio-group .is-active .el-radio-button__inner{
  background: #F5F5F5!important;
}
/deep/.el-radio-group .el-radio-button--medium .el-radio-button__inner{
  border-color: #DCDFE6;
  color: #333333;
  box-shadow:none
}
/deep/.is-checked .el-radio__inner{
  border-color: #FD4446!important;
  background: #FD4446!important;
}
/deep/.el-radio__inner{
  width: 20px;
  height: 20px;
}
/deep/.is-checked .el-radio__label{
  color: #333333!important;
  font-size: 18px;
}
/deep/.el-radio .el-radio__label{
  color: #1E63F1;
  font-size: 18px;
}
/deep/.el-icon-success{
  color: #20D08C;
}
/deep/.el-checkbox__inner:hover{
  border-color: #FD4446!important;
}
.goods_info{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /deep/ .el-radio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .goods-img {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.PlanStepThree-bj{
  /*height: calc(100vh - 200px);*/
  background-color: #fff;
}
.PlanStepThree{
  padding: 20px 30px;
  .PlanStepThreeHeader{
    color: #333333;
    font-size: 28px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
  }
  .uniNameBox{
    /*padding-bottom: 30px;*/
    span{
      color: #333333;
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      min-width: 72px;
      display: block;
    }
    .unitName{
      display: flex;
      align-items: center;
      padding-top: 50px;
    }
    .unitPrice{
      display: flex;
      align-items: center;
      padding-left: 132px;
      padding-top: 50px;
    }
  }
}
.PlanStepThree2 {
  padding: 20px 30px;
  .PlanStepThree2Header{
    span:nth-child(1){
      font-size: 18px;
    }
    .headerBtn{
      height: 36px;
      line-height: 0;
    }
  }
}
.PlanStepThree3 {
  padding: 20px 30px;
  .PlanStepThree3TableHeader{
    .Header1{
      display: flex;
      align-items: center;
    }
    .Header2{
      margin: 30px 0 15px;
    }
  }
}
.PlanStepThree4 {
  padding: 20px 30px;
  .PlanStepThree4Header{
    span:nth-child(1){
      font-width: 500;
    }
    span:nth-child(2){
      margin-left: 20px;
    }
    span{
      font-size: 18px;
      color: #333;
      font-weight: 400;
    }
    i{
      margin: 0 10px;
    }
  }
  .PlanStepThree4Table{
    padding: 20px;
    border: 1px solid rgba(151, 151, 151,0.3);
    margin-left: 94px;
    margin-top: 16px;
    .PlanStepThree4TableHeader{
      .Header1{
        display: flex;
        align-items: center;
        .add-key-word {
          cursor: pointer;
          color: #1E63F1;
          font-size: 18px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
        }
      }
      .Header2{
        margin-top: 14px;
      }
    }
    .tableBox{
      margin-left: 110px;
      margin-top: 20px;
      .table-header{
        height: 50px;
        background: #F0F0F0;
        color: #333333;
        font-size: 18px;
        line-height: 50px;
        padding-left: 20px;
      }
    }
  }
}
.cut-off{
  width: 100%;
  height: 20px;
  background: #F7F9FB;
}
.cut-off-btn{
  padding: 30px;
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  .offBtn{
    width: 140px;
    height: 50px;
    font-size: 20px;
  }
  .offBtn:nth-child(2){
    background: #FD4446;
    border-color: #FD4446;
    color: #ffffff;
  }
  .offBtn:nth-child(3){
    background: #FD4446;
    border-color: #FD4446;
    color: #ffffff;
  }
}
.territory{
  padding: 0 60px;
  .territory-title{
    color: #333333;
    font-size: 24px;
    font-weight: 500;
  }
  .territory-select{
    margin-top: 20px;
    /deep/ .el-cascader-panel {
      margin-top: 20px;
      height: 365px;
      .el-cascader-menu {
        border-right: solid 1px #E4E7ED;
      }
    }
  }
  .territory-btn{
    padding-top: 300px;
    display: flex;
    justify-content: space-between;
    .btns{
      width: 120px;
      height: 50px;
      background-color: #FD4446;
      border-color: #FD4446;
      color: #ffffff;
      font-size: 20px;
    }
  }
}
/deep/ .el-drawer {
  &.rtl {
    &:focus {
      outline: 0;
    }
  }
  .el-drawer__header {
    color: #333333;
    font-size: 24px;
    padding: 40px 20px 0 60px;
    margin-bottom: 0;
    font-weight: 500;
    span[role='heading'] {
      &:focus {
        outline: 0;
      }
    }
  }
}
.custom-box {
  /deep/ .el-scrollbar__view {
    /*position: relative;*/
    min-height: 100%;
  }
}

//s关键词页面
.custom-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 60px;
  span {
    color: #989898;
    font-size: 18px;
    line-height: 1.5;
  }
  .title {
    color: #333333;
    font-size: 24px;
    padding-bottom: 20px;
    margin-top: 0;
  }
  span:nth-child(4) {
    line-height: 27px;
  }
  .box-bottom {
    margin-top: 30px;
    font-size: 18px;
    color: #333333;
    text-align: right;
    /deep/ .btn-red {
      padding: 17px 45px;
      border-radius: 6px;
    }
  }
  .CustomBudget-radio {
    margin-top: 30px;
    font-size: 18px;
    color: #333333;
  }
  /deep/ .el-radio__input.is-checked .el-radio__inner {
    border-color: #FD4446;
    background: #FD4446;
  }
  /deep/ .el-radio__inner:hover {
    border-color: #FD4446;
  }
  /deep/ .el-radio__input.is-checked+.el-radio__label {
    color: #333;
  }
  .isShowActive {
    background: #FFECC8!important;
    border-color: #ffffff!important;
  }
  .select-keyword-table {
    width: 100%;
    /*height: 100%;*/
    height: calc(100% - 80px);
    .keyword-select {
      margin-bottom: 20px;
    }
    .select-table {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      .is-selected-tb {
        width: 60%;
        height: 100%;
        border: 1px solid #DFDFDF;
        .table-btn {
          padding: 10px 20px;
          span {
            font-size: 20px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #333333;
            margin-right: 20px;
          }
        }
        /deep/ .el-table--medium td, /deep/ .el-table--medium th {
          padding: 18px 0;
        }
        /deep/ .el-tabs {
          height: 100%;
          /deep/ .el-tabs__header {
            margin: 0;
            .el-tabs__item {
              padding-left: 20px;
              padding-top: 10px;
              font-weight: 500;
            }
          }
          /deep/ .el-tabs__content {
            height: calc(100% - 55px);
            .el-tab-pane {
              height: 100%;
            }
          }
        }
        .manual-input {
          margin: 20px;
        }
        ul.rule {
          margin: 0;
          padding: 0 20px 0 40px;
          list-style-type: none;
          li {
            span {
              font-size: 16px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            }
          }
        }
      }
      .selected-tb {
        width: calc(40% - 30px);
        height: 100%;
        margin-left: 30px;
        border: 1px solid #DFDFDF;
        /deep/ .el-table--medium td, /deep/ .el-table--medium th {
          padding: 14px 0;
        }
        .table-btn {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 10px 20px;
          span {
            font-size: 20px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #333333;
            margin-right: 20px;
          }

          /deep/ .el-button {
            padding: 7px 15px;
          }
        }
        /deep/ .el-input__inner {
          height: 30px;
          line-height: 30px;
        }
      }
    }
  }
}
/deep/ .el-drawer {
  &.rtl {
    &:focus {
      outline: 0;
    }
  }
  .el-drawer__header {
    color: #333333;
    font-size: 24px;
    padding: 40px 20px 0 60px;
    margin-bottom: 0;
    font-weight: 500;
    span[role='heading'] {
      &:focus {
        outline: 0;
      }
    }
  }
  .el-drawer__body {
    height: calc(100% - 74px);
  }
}
.el-tabs {
  /deep/ .el-tabs__header {
    margin: 0 0 32px;
    .el-tabs__active-bar {
      background-color: #FD4446;
    }
    .el-tabs__nav-wrap::after {
      height: 1px;
      background-color: #DFDFDF;
    }
    .el-tabs__item {
      font-size: 20px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      margin-bottom: 14px;
    }
  }
}

.is-error {
    /deep/ .el-input {
        input {
          border: 1px solid #FD4446;
        }
    }
}
.area-list {
    /deep/ .el-checkbox__input {
        &.is-checked {
            .el-checkbox__inner {
              border-color: #fd4446;
              background-color: #fd4446;
            }
        }
        &.is-indeterminate {
            .el-checkbox__inner {
              border-color: #fd4446;
              background-color: #fd4446;
            }
        }
    }
    /deep/ .el-checkbox {
        &.is-checked {
            .el-checkbox__label {
              color: #000;
            }
        }
    }
    .area-checkbox {
        margin-bottom: 10px;
        /deep/ .el-checkbox__input {
            &.is-checked {
                .el-checkbox__inner {
                    border-color: #fd4446;
                    background-color: #fd4446;
                }
            }
            &.is-indeterminate {
              .el-checkbox__inner {
                border-color: #fd4446;
                background-color: #fd4446;
              }
            }
        }
        /deep/ .el-checkbox {
            &.is-checked {
                .el-checkbox__label {
                    color: #000;
                }
            }
        }
    }
    /deep/ .el-cascader-menu {
        min-width: 50%;
        .el-scrollbar__wrap {
            margin-bottom: 0 !important;
            height: 100%;
        }
        .el-cascader-menu__list {
            &.el-scrollbar__view {
                min-width: 100% !important;
            }
            .el-checkbox {
                &.is-checked {
                    .el-checkbox__input {
                        &.is-checked {
                            .el-checkbox__inner {
                              border-color: #fd4446;
                              background-color: #fd4446;
                            }
                        }
                    }

                }
            }
            .el-cascader-node {
                &.in-active-path {
                    color: #000;
                }
                &.is-active {
                    color: #000;
                }
            }
            .el-checkbox__inner {
                &:hover {
                    border-color: #FD4446;
                }
            }
        }
    }
}
.table-btn {
  .search-input {
      width: 300px;
  }
}

//e关键词页面
